import { LoanEstimateRecord, LoanMetadata } from './types'
import { formatNumberToDollarAmount } from '@mortgage-pos/utils'
import { parsePhoneNumber } from 'libphonenumber-js'
import { PreApplicationData } from '@mortgage-pos/generated-documents'
import { sanitizeName } from './sanitizeName'
import {
  calculateCashToFromBorrower,
  calculateEstimatedLoanCosts,
  calculatePrincipalCurtailment,
  calculateTotalEstimatedPayoffsAndPayments,
  getLenderCreditValue,
} from './calculatorsAndGetters'
import { productConfig } from '@mortgage-pos/data'
import { EscrowOptions, LoanPurpose, ProductFamily } from '@mortgage-pos/types'

const PROPERTY_TAX_MONTHS_DEFAULT = 2
const HOI_MONTHS_DEFAULT = 2
const HOI_MONTHS_WAIVE_ESCROW = 12

/**
 *  Helper function to extract relevant loan information data
 *  from the correct source
 * @param { LoanEstimateRecord }
 * @returns { LoanMetadata }
 */
export function transformLoanMetadata(
  loanEstimateRecord: LoanEstimateRecord
): LoanMetadata {
  const { rate, latestRevision, loanOfficer } = loanEstimateRecord
  const loanData: LoanMetadata = { loanOfficer: {} }

  loanData.name =
    rate.borrowerName ||
    `${latestRevision?.first_name ?? ''} ${latestRevision?.last_name ?? ''}`

  loanData.address = rate.address
    ? `${rate.address ?? ''} ${rate.address2 ?? ''}`
    : `${latestRevision?.address ?? ''} ${latestRevision?.address_2 ?? ''}`

  loanData.city = rate.city || latestRevision?.city || ''

  loanData.loanOfficer.name = rate.loanOfficer?.name || loanOfficer?.name || ''

  loanData.loanOfficer.phone = rate.loanOfficer?.phone
    ? parsePhoneNumber(rate.loanOfficer?.phone, 'US').formatNational()
    : ''

  loanData.loanOfficer.nmls = rate.loanOfficer?.nmls || loanOfficer?.nmls || ''

  loanData.loanOfficer.email =
    rate.loanOfficer?.email || loanOfficer?.email || ''

  loanData.state = rate.state || latestRevision?.state || ''

  loanData.zip = rate.zip || latestRevision?.zip || ''

  loanData.propertyValue = rate.propertyValue
    ? formatNumberToDollarAmount(rate.propertyValue)
    : formatNumberToDollarAmount(latestRevision?.property_value) || ''

  loanData.loanPurpose = rate.loanPurpose

  return loanData
}

export const transformLoanToPreApplicationData = (
  loanEstimateRecord: LoanEstimateRecord
) => {
  const { rate, latestRevision, loanOfficer } = loanEstimateRecord
  const { waiveEscrow, escrowOptions, loanPurpose } = rate || {}
  const loanEstimates = rate.fees
    .filter((fee) => !!fee.amount)
    .map((fee) => ({
      label: sanitizeName(fee.description),
      value: formatNumberToDollarAmount(fee.amount, 0),
    }))

  const lenderCreditValue = getLenderCreditValue({ loanEstimateRecord })
  loanEstimates.push({
    label: 'Lender Credit',
    value: formatNumberToDollarAmount(lenderCreditValue || 0, 0),
  })

  const sageClosingCreditOffer = rate.loanEstimateSections['J'].fees.find(
    (f) => f.description === 'Sage Closing Credit Offer'
  )

  if (sageClosingCreditOffer) {
    loanEstimates.push({
      label: 'Sage Closing Credit Offer*',
      value: formatNumberToDollarAmount(sageClosingCreditOffer.amount || 0, 0),
    })
  }

  const calculatedEstimateCosts = calculateEstimatedLoanCosts({
    loanEstimateRecord,
  })

  const totalEstimatedPayoffsAndPayments = Math.round(
    calculateTotalEstimatedPayoffsAndPayments({ loanEstimateRecord })
  )
  const principalCurtailment = Math.round(
    calculatePrincipalCurtailment({
      loanEstimateRecord,
    }) * -1
  )

  const downPayment =
    rate.loanPurpose === 'Purchase'
      ? Math.round((loanEstimateRecord.rate as any).propertyDownPayment)
      : null

  let totalEstimatedPrepaid = rate.loanEstimateSections['F'].total

  // Reserves Deposited
  const reservesDeposited: { label: string; value: string }[] = []

  // Waive Escrow first
  if (waiveEscrow) {
    // Property Tax
    reservesDeposited.push({
      label: 'Property Taxes (0 mths)',
      value: formatNumberToDollarAmount(0, 0),
    })

    // HOI - Purchase should always collect 12 months of HOI at closing, even if monthly is waived
    let hoiAmount = 0
    if (loanPurpose === LoanPurpose.Purchase) {
      hoiAmount = rate.monthlyInsurance * HOI_MONTHS_WAIVE_ESCROW
      reservesDeposited.push({
        label: "Homeowner's Ins (" + HOI_MONTHS_WAIVE_ESCROW + ' mths)',
        value: formatNumberToDollarAmount(hoiAmount, 0),
      })
    } else {
      reservesDeposited.push({
        label: "Homeowner's Ins (0 mths)",
        value: formatNumberToDollarAmount(0, 0),
      })
    }

    totalEstimatedPrepaid += hoiAmount
  }

  // Escrow not waived
  if (!waiveEscrow) {
    const propertyTaxMonths =
      escrowOptions === EscrowOptions.InsuranceOnly
        ? 0
        : PROPERTY_TAX_MONTHS_DEFAULT
    const propertyTaxAmount =
      escrowOptions === EscrowOptions.InsuranceOnly
        ? 0
        : rate.monthlyPropertyTax * propertyTaxMonths

    const hoiMonths =
      loanPurpose === LoanPurpose.Purchase &&
      escrowOptions === EscrowOptions.TaxesOnly
        ? HOI_MONTHS_WAIVE_ESCROW
        : escrowOptions === EscrowOptions.TaxesOnly
        ? 0
        : rate.hoiMonths ?? HOI_MONTHS_DEFAULT
    const hoiAmount =
      loanPurpose === LoanPurpose.Refinance &&
      escrowOptions === EscrowOptions.TaxesOnly
        ? 0
        : rate.monthlyInsurance * hoiMonths

    reservesDeposited.push({
      label: 'Property Taxes (' + propertyTaxMonths + ' mths)',
      value: formatNumberToDollarAmount(propertyTaxAmount, 0),
    })
    totalEstimatedPrepaid += propertyTaxAmount

    reservesDeposited.push({
      label: "Homeowner's Ins (" + hoiMonths + ' mths)',
      value: formatNumberToDollarAmount(hoiAmount, 0),
    })

    totalEstimatedPrepaid += hoiAmount
  }

  const cashToFrom = calculateCashToFromBorrower({ loanEstimateRecord })
  const insurancePremium =
    rate.monthlyPrivateMortgageInsurance ||
    rate.mortgageInsurancePremium.monthlyDollarPremium

  const fundingFee = rate.fundingFee || 0
  const preApplicationData: PreApplicationData = {
    borrowerFullName:
      rate.borrowerName ||
      `${latestRevision?.first_name ?? ''} ${latestRevision?.last_name ?? ''}`,
    propertyAddress: rate.address
      ? `${rate.address ?? ''} ${rate.address2 ?? ''}`
      : `${latestRevision?.address ?? ''} ${latestRevision?.address_2 ?? ''}`,
    propertyCity: rate.city || latestRevision?.city || '',
    loFullName: rate.loanOfficer?.name || loanOfficer?.name || '',
    loPhone:
      rate.loanOfficer?.phone || loanOfficer?.phone
        ? parsePhoneNumber(
            rate.loanOfficer?.phone || loanOfficer?.phone,
            'US'
          ).formatNational()
        : '',
    nmls: rate.loanOfficer?.nmls || loanOfficer?.nmls || '',
    loEmail: rate.loanOfficer?.email || loanOfficer?.email || '',
    propertyState: rate.state || latestRevision?.state || '',
    propertyZip: rate.zip || latestRevision?.zip || '',
    propertyValue: rate.propertyValue
      ? formatNumberToDollarAmount(rate.propertyValue, 0)
      : formatNumberToDollarAmount(latestRevision?.property_value, 0) || '',
    loanPurpose: rate.loanPurpose,
    datePrepared: new Date().toLocaleDateString(),

    // Proposed terms
    loanAmount: formatNumberToDollarAmount(rate.loanAmount, 0),
    loanType: rate.type,
    loanTerm: rate.term,
    loanProduct: ['Va', 'Fha'].includes(rate.family)
      ? rate.family.toUpperCase()
      : rate.family,
    loanClass: rate.productClass,
    interestRate: rate.rate?.toFixed(3),
    ltv: rate.loanToValue?.toFixed(2),
    apr: rate.apr?.toFixed(3),
    isVha: rate.family?.toLowerCase() == 'va',

    // Estimated loan costs
    totalEstimate: formatNumberToDollarAmount(calculatedEstimateCosts, 0),
    loanEstimates,
    sageClosingCreditOfferNote: !!sageClosingCreditOffer,

    // Summary
    downPayment: downPayment ? formatNumberToDollarAmount(downPayment, 0) : '',
    principalCurtailment: principalCurtailment
      ? formatNumberToDollarAmount(principalCurtailment, 0)
      : '',
    outstandingBalance: formatNumberToDollarAmount(
      rate.loanAmount - rate.cashOut - rate.rollInFees - fundingFee,
      0
    ),
    fundingFee: fundingFee ? formatNumberToDollarAmount(fundingFee, 0) : null,
    outstandingBalanceTitle:
      rate.loanPurpose == 'Purchase'
        ? 'Base Loan Amount'
        : 'Outstanding Balance',
    estimatedPayoff: formatNumberToDollarAmount(
      totalEstimatedPayoffsAndPayments || 0,
      0
    ),
    summaryLoanAmount: formatNumberToDollarAmount(-1 * rate.loanAmount, 0),

    // Monthly (Not to be Rounded)
    pAndI: formatNumberToDollarAmount(rate.principleAndInterestPayment),
    homeownersInsurance: formatNumberToDollarAmount(
      waiveEscrow || escrowOptions === EscrowOptions.TaxesOnly
        ? 0
        : rate.monthlyInsurance
    ),
    pmi: formatNumberToDollarAmount(insurancePremium),
    monthlyInsuranceType: rate?.family === ProductFamily.Fha ? 'MIP' : 'PMI',
    propertyTaxes: formatNumberToDollarAmount(
      waiveEscrow || escrowOptions === EscrowOptions.InsuranceOnly
        ? 0
        : rate.monthlyPropertyTax
    ),
    totalEstimatedMonthlyPayment: formatNumberToDollarAmount(
      rate.monthlyPayment
    ),

    // Prepaid items
    itemsRequiredToPay: [
      {
        label: 'Daily Int. 15 Days',
        value: formatNumberToDollarAmount(
          rate.loanEstimateSections['F'].total,
          0
        ),
      },
    ],
    reservesDeposited,
    totalEstimatedPrepaid: formatNumberToDollarAmount(totalEstimatedPrepaid, 0),

    // So this feels backwards... But that's how we display it
    toFrom: cashToFrom > 0 ? 'From' : 'To',
    cashToFromBorrower: formatNumberToDollarAmount(cashToFrom, 0),
    companyLegalName: productConfig.getCompanyLegalName(latestRevision.state),
  }
  return preApplicationData
}
